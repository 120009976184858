var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { ImageFormat } from 'corgi-types';
import { borderColor, borderWidth, backgroundColor as styledBackgroundColor, themeGet, } from 'styled-system';
import { useLoadingImageBackground } from '../hooks/image';
import { getForwardPropOpts } from '../theme/helpers/forward-props';
import { boxSizing, objectFit } from '../theme/system-utilities';
import { breakpoints } from '../theme/theme';
import { transformImageUrl } from '../utils/cdn/image';
import { createLazyLoadingAttribute } from '../utils/image';
var Image = styled('img', getForwardPropOpts())(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus {\n    border: 2px solid ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus {\n    border: 2px solid ", ";\n  }\n"])), borderColor, borderWidth, styledBackgroundColor, boxSizing, objectFit, themeGet('colors.sys.secondary.border.focus'));
var imageStyles = {
    boxSizing: 'border-box',
    objectFit: 'contain',
};
var buildSrcSet = function (format, originalUrl, width, maxDpr
// eslint-disable-next-line max-params
) {
    var result = [];
    for (var dpr = 1; dpr <= maxDpr; dpr++) {
        var roundedWidth = getRoundedSizeBy50px(width * dpr);
        var url = transformImageUrl(originalUrl, {
            format: format.toLowerCase(),
            width: roundedWidth,
        });
        result.push("".concat(url, " ").concat(dpr, "x"));
    }
    return result.join(',');
};
var maxWidthQuery = function (width) { return "(max-width: ".concat(width, "px)"); };
var minWidthQuery = function (width) { return "(min-width: ".concat(width, "px)"); };
var breakpointSizes = breakpoints.map(function (b) { return parseInt(b.slice(0, -2), 10); });
var buildSourcesWithQueries = function (ranges, widths) {
    return [ImageFormat.AVIF, ImageFormat.WEBP].reduce(function (accumulator, format) { return __spreadArray(__spreadArray([], __read(accumulator), false), __read(ranges.map(function (range) {
        var _a = __read(range, 2), from = _a[0], to = _a[1];
        var mediaQuery = to === 3
            ? minWidthQuery(breakpointSizes[from - 1])
            : maxWidthQuery(breakpointSizes[to] - 1);
        return {
            width: widths[to],
            format: format,
            mediaQuery: mediaQuery,
        };
    })), false); }, []);
};
var buildSourcesWithoutQueries = function (width) {
    return [ImageFormat.AVIF, ImageFormat.WEBP].map(function (format) { return ({ width: width, format: format }); });
};
var getRoundedSizeBy50px = function (size) {
    if (size <= 50) {
        return 50;
    }
    if (size <= 200) {
        return Math.ceil(size / 50) * 50;
    }
    if (size >= 1000) {
        return Math.floor(size / 50) * 50;
    }
    return Math.round(size / 50) * 50;
};
var areSizesEqualForAllDensities = function (currentSize, previousSize, maxDpr) {
    for (var dpr = 1; dpr <= maxDpr; dpr++) {
        if (getRoundedSizeBy50px(currentSize * dpr) !== getRoundedSizeBy50px(previousSize * dpr)) {
            return false;
        }
    }
    return true;
};
var getWidthRanges = function (maxDpr, widths) {
    var start = 0;
    var end = 0;
    var ranges = [];
    for (var i = 1; i < widths.length; i++) {
        if (areSizesEqualForAllDensities(widths[i], widths[i - 1], maxDpr)) {
            end = i;
        }
        else {
            ranges.push([start, end]);
            start = i;
            end = i;
        }
    }
    ranges.push([start, end]);
    return ranges;
};
var isSingleWidth = function (widths) {
    return !Array.isArray(widths);
};
var buildSources = function (maxDpr, widths) {
    if (!isSingleWidth(widths)) {
        var ranges = getWidthRanges(maxDpr, widths);
        if (ranges.length === 1) {
            return buildSourcesWithoutQueries(widths[0]);
        }
        return buildSourcesWithQueries(ranges, widths);
    }
    return buildSourcesWithoutQueries(widths);
};
var getPicture = function (_a) {
    var src = _a.src, widths = _a.widths, fallbackSize = _a.fallbackSize, is3xDprEnabled = _a.is3xDprEnabled;
    var maxDpr = is3xDprEnabled ? 3 : 2;
    var sources = buildSources(maxDpr, widths).map(function (source) { return ({
        width: source.width,
        height: source.width,
        media: source.mediaQuery,
        srcSet: buildSrcSet(source.format, src, source.width, maxDpr),
        type: "image/".concat(source.format.toLowerCase()),
    }); });
    return {
        fallback: transformImageUrl(src, {
            width: getRoundedSizeBy50px(fallbackSize),
            format: ImageFormat.JPG,
        }),
        sources: sources,
    };
};
var ResponsiveImage = function (_a) {
    var src = _a.src, widths = _a.widths, _b = _a.fallbackSize, fallbackSize = _b === void 0 ? Array.isArray(widths) ? widths[widths.length - 1] : widths : _b, _c = _a.alt, alt = _c === void 0 ? '' : _c, _d = _a.isLazy, isLazy = _d === void 0 ? false : _d, _e = _a.isLoading, isLoading = _e === void 0 ? false : _e, className = _a.className, onClick = _a.onClick, onTouchStart = _a.onTouchStart, is3xDprEnabled = _a.is3xDprEnabled, onLoad = _a.onLoad, highPriority = _a.highPriority, _f = _a.showPlaceholder, showPlaceholder = _f === void 0 ? true : _f, props = __rest(_a, ["src", "widths", "fallbackSize", "alt", "isLazy", "isLoading", "className", "onClick", "onTouchStart", "is3xDprEnabled", "onLoad", "highPriority", "showPlaceholder"]);
    var imgRef = useRef(null);
    var _g = useMemo(function () {
        return getPicture({
            src: src,
            widths: widths,
            fallbackSize: fallbackSize,
            is3xDprEnabled: is3xDprEnabled,
        });
    }, [src, widths, fallbackSize, is3xDprEnabled]), fallback = _g.fallback, sources = _g.sources;
    var backgroundColor = useLoadingImageBackground(!showPlaceholder || !isLazy, imgRef).backgroundColor;
    return (React.createElement("picture", null,
        sources.map(function (source, idx) { return (React.createElement("source", __assign({ key: idx }, source))); }),
        React.createElement(Image, __assign({}, props, imageStyles, createLazyLoadingAttribute(isLazy), { ref: imgRef, src: fallback, alt: alt, onClick: onClick, onTouchStart: onTouchStart, onLoad: onLoad, className: className, width: fallbackSize, height: fallbackSize, "aria-live": "polite", "aria-busy": isLoading }, (highPriority && { fetchpriority: 'high' }), (backgroundColor && { backgroundColor: backgroundColor })))));
};
export default ResponsiveImage;
var templateObject_1;
